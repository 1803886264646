import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function PermissionsModal({ user, setOpenPermissions }) {
  const dispatch = useDispatch();

  const [isToggle, setIsToggle] = useState(false);
  const [permissionData, setPermissionData] = useState({});
  const [loading, setLoading] = useState(true);
  const [toggleLoading, setToggleLoading] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (user) {
          const response = await dispatch.permissions.getUserPermissions({
            user_id: user?.user_id?._id,
            project_id: user?.project_id,
          });
          setPermissionData(response.data);
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [user]);

  const handleToggle = async (action) => {
    setToggleLoading(action);

    const updatedPermissions = permissionData.permissions.map((item, i) =>
      item.action === action ? { ...item, allowed: !item.allowed } : item
    );

    setPermissionData((prevData) => ({
      ...prevData,
      permissions: updatedPermissions,
    }));

    try {
      const response = await dispatch.permissions.editUserPermissions({
        user_id: user?.user_id?._id,
        project_id: user?.project_id,
        permissions: updatedPermissions,
      });

      if (response.success) {
        toast.success(response?.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error updating permissions:", error);
    } finally {
      setToggleLoading(null);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Overlay */}
      <div
        className="absolute inset-0 bg-greyColor-200 opacity-50"
        onClick={() => setOpenPermissions(false)}
      ></div>

      {/* Modal Box */}
      {loading ? (
        <div className="w-full flex items-center justify-center min-h-64 z-50">
          <p className="text-white">Loading...</p>
        </div>
      ) : (
        <div className="bg-white p-10 rounded-lg shadow-lg z-50 relative">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-6 top-6 opacity-50 cursor-pointer"
            onClick={() => setOpenPermissions(false)}
          >
            <path
              d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
              fill="#1D1B20"
            />
          </svg>

          <div className="w-full">
            <div className="flex items-center justify-between gap-7 border-b py-4 pb-6 mb-8">
              <img
                src={`https://i.pravatar.cc/100?img=${user?.user_id._id}`}
                alt={user?.user_id?.first_name}
                className="w-12 h-12 rounded-full"
              />
              <div>
                <h3 className="text-2xl font-normal text-gray-500 mb-1">
                  User Project Permissions
                </h3>
                <p className="text-gray-500 text-sm font-light">
                  Setting permissions for{" "}
                  <span className="text-black">
                    {user?.user_id?.first_name}
                  </span>{" "}
                  on this Project
                </p>
              </div>
            </div>
            {permissionData?.permissions?.length > 0 ? (
              permissionData.permissions
                ?.filter(
                  (item) =>
                    item.action === "Access-Allowed" ||
                    item.action === "Admin-Privilege"
                )
                .map((item, index) => (
                  <div key={index} className="w-full mt-2 mb-6 px-4">
                    <div className="w-full flex items-center justify-between">
                      <p>{item?.action}</p>
                      {toggleLoading === item.action ? (
                        <p className="text-xs font-thin text-gray-600">
                          Loading...
                        </p>
                      ) : (
                        <div
                          className={`inline-flex py-[2px] items-center cursor-pointer ${
                            item?.allowed
                              ? "pl-6 pr-1 bg-green-500"
                              : "pr-6 pl-1 bg-gray-400"
                          } rounded-full`}
                          onClick={() => handleToggle(item.action)}
                        >
                          <div className="p-[8px] bg-white rounded-full"></div>
                        </div>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <p className="text-gray-500 mt-4">No permissions found.</p>
            )}

            <div className="border mt-6 rounded-xl p-2 pb-6 flex flex-col items-center justify-center gap-4">
              {permissionData?.permissions?.length > 0 &&
                permissionData.permissions
                  ?.filter(
                    (item) =>
                      item.action !== "Access-Allowed" &&
                      item.action !== "Admin-Privilege"
                  )
                  .map((item, index) => (
                    <div key={index} className="w-full mt-4 px-4">
                      <div className="w-full flex items-center justify-between">
                        <p>{item?.action}</p>
                        {toggleLoading === item.action ? (
                          <p className="text-[10px] font-thin text-gray-500">
                            Loading...
                          </p>
                        ) : (
                          <div
                            className={`inline-flex py-[2px] items-center cursor-pointer ${
                              item?.allowed
                                ? "pl-6 pr-1 bg-green-500"
                                : "pr-6 pl-1 bg-gray-400"
                            } rounded-full`}
                            onClick={() => handleToggle(item.action)}
                          >
                            <div className="p-[8px] bg-white rounded-full"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PermissionsModal;
