import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TechnologyModalLayout from "../../components/Common/TechnologyModalLayout";
import AddTechnology from "./AddTechnology";
import { toast } from "react-toastify";
const Technologies = ({
  DetailedProjectData,
  projectTechnologyUpdation,
  projectTechnologyCreation,
  technologyResponseData,
  TechnologyNameSearch,
  projectTechnologyDelete,
}) => {
  const technologiesDataServer =
    DetailedProjectData &&
    DetailedProjectData.project &&
    DetailedProjectData.project.technologies.server;
  const technologiesDataClient =
    DetailedProjectData &&
    DetailedProjectData.project &&
    DetailedProjectData.project.technologies.client;
  const [open, SetOpenModal] = useState(false);
  const [techType, SettechType] = useState("");
  const [editData, setEditData] = useState(null);
  //console.log("technologies",technologiesDataClient);
  const [toggle, setToggle] = useState(false);
  const toggleClass =
    " transform translate-x-5 transition delay-150 duration-150 ease-linear";
  //  setstate(active)
  // const [technologyData, setTechnologyData] = useState({
  //     techId: "",
  //     version: "1.1",
  //     note: "fvxcv",
  //     reason: "dssd",
  //     newtechnologyname: "",
  //     status: ""
  // });
  // console.log(technologyData);
  const addTechnology = async (technologyData, editdata) => {
    try {
      if (editdata) {
        const responseData = await projectTechnologyUpdation({
          project_id: DetailedProjectData.project._id,
          ...technologyData,
          tech_id: editdata._id,
        });
        TechnologyNameSearch();
        SetOpenModal(false);
        if (responseData && responseData.success) {
          toast.success("Project technology updated");
        } else {
          toast.error(responseData.message);
        }
        return responseData;
      } else {
        const responseData = await projectTechnologyCreation({
          project_id: DetailedProjectData.project._id,
          ...technologyData,
        });
        TechnologyNameSearch();
        SetOpenModal(false);
        if (responseData && responseData.success) {
          toast.success("Project technology created");
        } else {
          toast.error(responseData.message);
        }
        return responseData;
      }
    } catch (err) {
      console.log(err);
      toast.error(err.error);
    }
  };
  const removeTechnology = async (data) => {
    if (window.confirm("Are you sure to delete the item?")) {
      projectTechnologyDelete(data);
    }
  };
  const handleModal = () => {
    SetOpenModal(!open);
    //addTechnology(technologyData)
  };
  //   console.log(technologyData);
  useEffect(() => {
    TechnologyNameSearch();
  }, []);
  useEffect(() => {
    if (!open) {
      setEditData(null);
    }
  }, [open]);
  return (
    <div>
      <div className="flex flex-col lg:flex-row md:flex-row">
        <div className="basis-1/2  lg:mr-3 md:mr-2 mb-8 p-4 rounded bg-secondary-grey-100">
          <div className="flex items-center lg:flex-row justify-between pb-4">
            <h3 className="font-title text-base text-black font-semibold">
              Server Side
            </h3>
            <button
              onClick={() => {
                handleModal();
                SettechType("server");
              }}
              className="group font-title flex items-center hover:text-green-900 text-green-700 py-2 px-2 hover:border-transparent text-tiny font-medium transition duration-300 ease-out hover:bg-opacity-80 h-8 rounded-md"
            >
              <svg
                className="fill-green-700 group-hover:fill-green-900"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.66495 2.66666H7.33188V7.33332H2.66614V8.66666H7.33188V13.3333H8.66495V8.66666H13.3307V7.33332H8.66495V2.66666Z"
                />
              </svg>{" "}
              <span className="ml-1">Add</span>
            </button>
          </div>
          {technologiesDataServer && technologiesDataServer.length ? (
            <div className="rounded rounded-md w-full min-h-[200px] grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3  2xl:grid-cols-3 gap-2">
              {technologiesDataServer.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    item && item.status === false ? "opacity-40" : ""
                  }  rounded bg-white rounded-md bg-white border border-gray-200 p-3.5 h-24 relative flex flex-row justify-between w-full m-1`}
                >
                  <div>
                    <div className="text-tiny text-primary font-medium text-title capitalize basis-1/2 break-all pr-2">
                      <div className="flex flex-col">
                        <span className="block">
                          {item.techId.name ? item.techId.name : ""}
                        </span>
                        <span className="text-gray-400 font-normal  text-tiny">
                          {item.version ? item.version : "Version"}
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-start text-tiny text-title  basis-3/6 ">
                      <div className="group  mr-1 ">
                        <div className="flex items-center rounded-full p-1 bg-gray-100">
                          <svg
                            style={{ width: "10px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M13.25 7c0 .69-.56 1.25-1.25 1.25s-1.25-.56-1.25-1.25.56-1.25 1.25-1.25 1.25.56 1.25 1.25zm10.75 5c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-2 0c0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10 10-4.486 10-10zm-13-2v2h2v6h2v-8h-4z"
                              fill="#B6BABD"
                            />
                          </svg>
                        </div>
                        <div
                          className="invisible group-hover:visible absolute  rounded max-w-xs mx-auto flex justify-center bg-primary-blue-400 border border-slate-400 p-3 left-0 right-0"
                          style={{ bottom: "48px" }}
                        >
                          <div className="p-1.5">
                            {" "}
                            {item && item.note ? item.note : "No data"}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group relative flex justify-start flex-col">
                    <button type="button">
                      <svg
                        className="fill-[#B6BABD] group-hover:fill-primary-navyBlue"
                        width="20"
                        height="14"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m16.5 11.995c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25zm-6.75 0c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25zm-6.75 0c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25z" />
                      </svg>
                    </button>
                    <div
                      className={`invisible group-hover:visible flex-col absolute top-4 right-0 w-20 rounded bg-white transition duration-300 ease-out drop-shadow-md z-20}`}
                    >
                      <button
                        onClick={() => {
                          handleModal();
                          SettechType("server");
                          setEditData(item);
                        }}
                        className="w-full px-2 py-2 flex justify-start text-xs transition duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 30 24"
                        >
                          <path
                            d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"
                            fill="#B6BABD"
                          />
                        </svg>
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          removeTechnology({
                            tech_id: item._id,
                            platform: "server",
                            project_id: DetailedProjectData.project._id,
                          });
                        }}
                        className="w-full px-2 py-2 flex justify-start text-xs transition duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50"
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 30 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        >
                          <path
                            d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-9 4c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm-2-7h-4v1h4v-1z"
                            fill="red"
                          />
                        </svg>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full text-sm font-medium text-center py-3 text-gray-500">
              No data
            </div>
          )}
        </div>
        <div className="basis-1/2  mb-8 p-4 rounded bg-secondary-grey-100">
          <div className="flex items-center lg:flex-row justify-between pb-4">
            <h3 className="font-title text-base text-black text-title font-semibold ">
              Client Side
            </h3>
            <button
              onClick={() => {
                handleModal();
                SettechType("client");
              }}
              className="group font-title flex items-center hover:text-green-900 text-green-700 py-2 px-2 text-tiny font-medium transition duration-300 ease-out hover:bg-opacity-80 h-8 rounded-md"
            >
              <svg
                className="fill-green-700 group-hover:fill-green-900"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.66495 2.66666H7.33188V7.33332H2.66614V8.66666H7.33188V13.3333H8.66495V8.66666H13.3307V7.33332H8.66495V2.66666Z"
                />
              </svg>
              <span className="ml-1">Add</span>
            </button>
          </div>
          {technologiesDataClient && technologiesDataClient.length ? (
            <div className="rounded rounded-md w-full min-h-[200px] grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3  2xl:grid-cols-3 gap-2">
              {technologiesDataClient.map((item, index) => (
                <div
                  key={index}
                  className="rounded bg-white rounded-md bg-white border border-gray-200 p-3.5 h-24 relative flex flex-row justify-between w-full m-1"
                >
                  <div>
                    <div className="text-tiny text-primary font-medium text-title capitalize basis-1/2 break-all pr-2">
                      <div className="flex flex-col">
                        <span className="block">
                          {item.techId.name ? item.techId.name : ""}
                        </span>
                        <span className="text-gray-400 font-normal  text-tiny">
                          {item.version ? item.version : "Version"}
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-start text-tiny text-title  basis-3/6 ">
                      <div className="group  mr-1 ">
                        <div className="flex items-center rounded-full p-1 bg-gray-100">
                          <svg
                            style={{ width: "10px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M13.25 7c0 .69-.56 1.25-1.25 1.25s-1.25-.56-1.25-1.25.56-1.25 1.25-1.25 1.25.56 1.25 1.25zm10.75 5c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-2 0c0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10 10-4.486 10-10zm-13-2v2h2v6h2v-8h-4z"
                              fill="#B6BABD"
                            />
                          </svg>
                        </div>
                        <div
                          className="invisible group-hover:visible absolute  rounded max-w-xs mx-auto flex justify-center bg-primary-blue-400 border border-slate-400 p-3 left-0 right-0"
                          style={{ bottom: "48px" }}
                        >
                          <div className="p-1.5">
                            {" "}
                            {item && item.note ? item.note : "No data"}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group relative flex justify-start flex-col">
                    <button type="button">
                      <svg
                        className="fill-[#B6BABD] group-hover:fill-primary-navyBlue"
                        width="20"
                        height="14"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m16.5 11.995c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25zm-6.75 0c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25zm-6.75 0c0-1.242 1.008-2.25 2.25-2.25s2.25 1.008 2.25 2.25-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25z" />
                      </svg>
                    </button>
                    <div
                      className={`invisible group-hover:visible flex-col absolute top-4 right-0 w-20 rounded bg-white transition duration-300 ease-out drop-shadow-md z-20}`}
                    >
                      <button
                        onClick={() => {
                          handleModal();
                          SettechType("client");
                          setEditData(item);
                        }}
                        className="w-full px-2 py-2 flex justify-start text-xs transition duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 30 24"
                        >
                          <path
                            d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"
                            fill="#B6BABD"
                          />
                        </svg>
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          removeTechnology({
                            tech_id: item._id,
                            platform: "client",
                            project_id: DetailedProjectData.project._id,
                          });
                        }}
                        className="w-full px-2 py-2 flex justify-start text-xs transition duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50"
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 30 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        >
                          <path
                            d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-9 4c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm-2-7h-4v1h4v-1z"
                            fill="red"
                          />
                        </svg>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-sm font-medium text-center py-3 text-gray-500">
              No data
            </div>
          )}
        </div>
      </div>
      {open ? (
        <TechnologyModalLayout closeModal={handleModal} open={open}>
          <AddTechnology
            editData={editData}
            technologyData={technologyResponseData}
            addTechnology={addTechnology}
            techType={techType}
          />
        </TechnologyModalLayout>
      ) : null}
    </div>
  );
};
const mapState = (state) => ({
  technologyResponseData: state.projects.technology,
});
const mapDispatch = (dispatch) => ({
  projectTechnologyUpdation: dispatch.projectDetails.projectTechnologyUpdation,
  projectTechnologyCreation: dispatch.projectDetails.projectTechnologyCreation,
  TechnologyNameSearch: dispatch.projects.technologyNameSearch,
  projectTechnologyDelete: dispatch.projectDetails.projectTechnologyDelete,
});
export default connect(mapState, mapDispatch)(Technologies);
