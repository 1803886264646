import React, { useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "./custom-select.css";

const AddMember = ({
  designationResponseData,
  designationSearch,
  rolesResponseData,
  rolesSearch,
  skillsResponseData,
  skillsSearch,
  register,
  closeModal,
  memberUpdate,
  memberEditData,
  onSuccess,
  SetOpenModal,
  open,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      designationSearch();
      rolesSearch();
      skillsSearch();
    };
    fetchData();
  }, [designationSearch, rolesSearch, skillsSearch]);

  return (
    <div>
      <div
        onClick={() => SetOpenModal(false)}
        className={` ${
          open
            ? "fixed inset-0 bg-black opacity-20 z-10"
            : "opacity-0 pointer-events-none"
        }`}
      ></div>
      <div
        className={`fixed inset-0 flex justify-center items-center z-50 transform transition-all duration-200 ease-out ${
          open
            ? "scale-100 opacity-100 visible" // Visible and fully opaque
            : "scale-95 opacity-0 invisible" // Invisible and transparent
        }`}
      >
        <div className="bg-white border p-11 rounded-3xl shadow-lg w-full max-w-lg relative">
          <div
            onClick={() => SetOpenModal(false)}
            className="absolute right-8 cursor-pointer top-8"
          >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.94165 11.2638L6.1613 6.15874L11.3809 11.2638M11.3809 1.05371L6.1603 6.15874L0.94165 1.05371"
                stroke="#B9B9B9"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="mb-8">
            <h3 className="font-title text-lg font-light text-black mb-1">
              {memberEditData && memberEditData._id
                ? "Edit Member"
                : "Add a New Member"}
            </h3>
            <span className="block text-sm font-normal text-secondary-grey-200">
              Enter basic information about the new member.
            </span>
          </div>

          <Formik
            key={open ? "open" : "closed"}
            initialValues={{
              first_name: (memberEditData && memberEditData.first_name) || "",
              last_name: (memberEditData && memberEditData.last_name) || "",
              designation:
                memberEditData &&
                memberEditData.designation &&
                memberEditData.designation._id
                  ? {
                      value: memberEditData.designation._id,
                      label: memberEditData.designation.name,
                    }
                  : "",
              roles:
                (memberEditData &&
                  memberEditData.roles &&
                  memberEditData.roles.length > 0 &&
                  memberEditData.roles.map((roleitem) => {
                    return roleitem._id;
                  })) ||
                [],
              email: (memberEditData && memberEditData.email) || "",
              password: "",
              skills:
                (memberEditData &&
                  memberEditData.skills &&
                  memberEditData.skills.length > 0 &&
                  memberEditData.skills.map((roleitem) => {
                    return roleitem._id;
                  })) ||
                [],
            }}
            validationSchema={Yup.object().shape({
              first_name: Yup.string().required("Enter First Name"),
              last_name: Yup.string().required("Enter Last Name"),
              designation: Yup.object().required("Choose Designation"),
              roles: Yup.array().min(1, "Choose Roles"),
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password:
                memberEditData && memberEditData._id
                  ? null
                  : Yup.string().required("Enter Password"),
              skills: Yup.array().min(1, "Choose Skills"),
            })}
            onSubmit={async (values, { resetForm }) => {
              values.designation = values.designation.value;
              if (memberEditData && memberEditData._id) {
                await memberUpdate({ ...values, user_id: memberEditData._id })
                  .then((response) => {
                    if (response.message && response.message.length > 0) {
                      toast.success(response.message);
                    }
                    if (response.success) {
                      onSuccess();
                      closeModal(true);
                    }
                  })
                  .catch((response) => {
                    if (response.error && response.error.length > 0) {
                      toast.error(response.error);
                    }
                  });
              } else {
                await register(values)
                  .then((response) => {
                    if (response.message && response.message.length > 0) {
                      toast.success(response.message);
                    }
                    if (response.status === 200) {
                      onSuccess();
                      closeModal(true);
                    }
                  })
                  .catch((response) => {
                    if (response.error && response.error.length > 0) {
                      toast.error(response.error);
                    }
                  });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} className="w-full">
                <div className="mb-8 relative grid grid-cols-2 gap-4">
                  <div>
                    <Field
                      className="appearance-none focus:outline-none focus:border-[#E5EAEF] transition delay-150 duration-150 ease-linear text-sm border rounded-md py-2 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-500 font-normal border-secondary-grey-400"
                      placeholder="First Name"
                      type="text"
                      name="first_name"
                      onChange={handleChange}
                      value={values.first_name}
                    />
                    {errors.first_name && touched.first_name && (
                      <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                        {errors.first_name}
                      </span>
                    )}
                  </div>
                  <div className="relative">
                    <Field
                      className="appearance-none focus:outline-none focus:border-[#E5EAEF] transition delay-150 duration-150 ease-linear text-sm border rounded-md py-2 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-500 font-normal border-secondary-grey-400"
                      placeholder="Last Name"
                      type="text"
                      name="last_name"
                    />
                    {errors.last_name && touched.last_name && (
                      <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                        {errors.last_name}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mb-8 relative">
                  <Select
                    className="custom-search-create custom-search-select appearance-none focus:outline-none focus:border-[#E5EAEF] transition delay-150 duration-150 ease-linear text-sm border rounded-md w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400 css-b62m3t-container"
                    name="designation"
                    defaultValue={
                      memberEditData && memberEditData.designation
                        ? {
                            value: memberEditData.designation._id,
                            label: memberEditData.designation.name,
                          }
                        : ""
                    }
                    //isMulti
                    isSearchable={true}
                    classNamePrefix="dropdownSelect"
                    isClearable={true}
                    options={designationResponseData.map((project) => ({
                      label: project.name,
                      value: project._id,
                    }))}
                    placeholder="Designation"
                    onChange={(e) => {
                      if (e) {
                        setFieldValue("designation", e);
                      } else {
                        setFieldValue("designation", "");
                      }
                    }}
                  />
                  {errors.designation && touched.designation && (
                    <span className="text-red-500 text-xs absolute -bottom-5">
                      {errors.designation}
                    </span>
                  )}
                  {/* <span className="absolute right-4 top-5 mt-1 z-0">
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 7L5.82818e-07 0.333333L10 0.333334L5 7Z" fill="#656A6F" />
                </svg>
              </span> */}
                </div>

                <div className="mb-8 relative">
                  <Select
                    className="custom-search-select custom-search-create focus:border-secondary-grey-300 transition delay-150 duration-150 ease-linear text-sm border rounded-md w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                    placeholder="Application Role"
                    styles={{
                      padding: "100px",
                    }}
                    type="text"
                    isMulti
                    name="roles"
                    classNamePrefix="dropdownSelect"
                    defaultValue={
                      (memberEditData &&
                        memberEditData.roles &&
                        memberEditData.roles.length > 0 &&
                        memberEditData.roles.map((roleitem) => {
                          return { value: roleitem._id, label: roleitem.name };
                        })) ||
                      []
                    }
                    options={rolesResponseData.map((project) => ({
                      label: project.name,
                      value: project._id,
                    }))}
                    onChange={(e) => {
                      setFieldValue(
                        "roles",
                        e.map((rol) => rol.value)
                      );
                    }}
                  />

                  {errors.roles && touched.roles && (
                    <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                      {errors.roles}
                    </span>
                  )}
                </div>

                <div className="mb-6 relative dddd">
                  <Field
                    className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-sm border rounded-md py-2 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-500 font-normal border-secondary-grey-400"
                    placeholder="Login Email"
                    type="text"
                    name="email"
                    autoComplete="off"
                  />
                  {errors.email && touched.email && (
                    <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                      {errors.email}
                    </span>
                  )}
                </div>

                {!(memberEditData && memberEditData._id) ? (
                  <div className="mb-6 relative">
                    <Field
                      className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-sm border rounded-md py-2 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                      placeholder="Password"
                      type="password"
                      name="password"
                    />
                    {errors.password && touched.password && (
                      <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                        {errors.password}
                      </span>
                    )}
                  </div>
                ) : null}

                <div className="mb-8 relative">
                  <Select
                    className="custom-search-select custom-search-create appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-sm border rounded-md w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                    placeholder="Skills"
                    type="text"
                    name="skills"
                    classNamePrefix="dropdownSelect"
                    defaultValue={
                      (memberEditData &&
                        memberEditData.skills &&
                        memberEditData.skills.length > 0 &&
                        memberEditData.skills.map((skillitem) => {
                          return {
                            value: skillitem._id,
                            label: skillitem.name,
                          };
                        })) ||
                      []
                    }
                    isMulti
                    options={skillsResponseData.map((project) => ({
                      label: project.name,
                      value: project._id,
                    }))}
                    onChange={(e) => {
                      setFieldValue(
                        "skills",
                        e.map((sk) => sk.value)
                      );
                    }}
                  />
                  {errors.skills && touched.skills && (
                    <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                      {errors.skills}
                    </span>
                  )}
                </div>
                <div className="w-full flex items-center justify-between pt-3">
                  <button
                    onClick={() => {
                      SetOpenModal(false);
                    }}
                    className="text-black border shadow-md rounded-md text-sm font-normal bg-white hover:bg-gray-200 px-10 py-2"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={isSubmitting}
                    className="text-white text-sm font-semibold bg-[#057D54] hover:bg-green-800 px-14 py-2 rounded-md shadow-md border"
                  >
                    Set
                  </button>
                </div>

                {/* <button
                  disabled={isSubmitting}
                  type="submit"
                  className="w-full p-4 bg-primary-navyBlue hover:bg-opacity-75 transition delay-150 duration-150 ease-linear text-white rounded-md text-sm font-semibold font-title"
                >
                  Save
                </button> */}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  designationResponseData: state.projects.designation,
  rolesResponseData: state.projects.roles,
  skillsResponseData: state.projects.skills,
});

const mapDispatch = (dispatch) => ({
  designationSearch: dispatch.projects.designationSearch,
  rolesSearch: dispatch.projects.rolesSearch,
  skillsSearch: dispatch.projects.skillsSearch,
  register: dispatch.projects.register,
  memberUpdate: dispatch.people.memberUpdate,
});

export default connect(mapState, mapDispatch)(AddMember);
