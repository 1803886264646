import {
  listUserPermissions,
  updateUserPermissions,
} from "../services/permissions";

export const permissions = {
  state: {
    permission: [],
  },
  reducers: {
    listPermissions: (state, data) => {
      return {
        ...state,
        permission: data,
      };
    },
  },
  effects: (dispatch) => ({
    async getUserPermissions(data) {
      try {
        const responseData = await listUserPermissions(data);
        this.listPermissions(responseData.data);
        return responseData.data;
      } catch (err) {
        console.log("err", err);
        throw err;
      }
    },
    async editUserPermissions(data) {
        try {
          const responseData = await updateUserPermissions(data);
          this.listPermissions(responseData.data);
          return responseData.data;
        } catch (err) {
          console.log("err", err);
          throw err;
        }
      },
  }),
};
