import { API_ROUTES } from "../config";
import { http } from "../http";

export const listUserPermissions = async (data) => {
  try {
    const responseData = await http().get(
      `${API_ROUTES["list-one-user-permission"]}?user_id=${data?.user_id}&project_id=${data.project_id}`
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

export const updateUserPermissions = async (data) => {
  try {
    const responseData = await http().put(
      `${API_ROUTES["edit-permission"]}?user_id=${data?.user_id}&project_id=${data?.project_id}`,
      data
    );
    return responseData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};
