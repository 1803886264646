import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import Loader from "../Utilities/Loader";
import Notes from "./Notes";

function Requirement({
  DetailedProjectData,
  projectRequirementCreation,
  projectRequirementUpdation,
  projectRequirementDelete,
  projectRequirementNoteCreation,
  projectRequirementNoteUpdation,
  projectRequirementNoteDelete,
}) {
  const [isRequirementLoading, setRequirementLoading] = useState(false);
  const [isNoteAdding, setNotAdding] = useState(false);
  const [isNoteUpdating, setNoteUpdating] = useState(false);
  const [isEditLoading, seteditLoading] = useState(false);
  const [isTitleEditing, setTitleEditing] = useState(false);
  const [editingItem, setEditingItem] = useState({
    _id: "",
    title: "",
    description: "",
    createdAt: "",
    status: "",
    updatedAt: "",
    notes: [],
  });
  const requirementData =
    DetailedProjectData &&
    DetailedProjectData.project &&
    DetailedProjectData.project.requirement;
  const [updatingValue, setUpdatingValue] = useState({
    project_id: "",
    requirement_id: "",
    title: "",
    description: "",
  });
  const titleRef = useRef(null);
  const timeoutRef = useRef(null);
  const AddRequirement = async () => {
    try {
      setRequirementLoading(true);
      const responseData = await projectRequirementCreation({
        project_id: DetailedProjectData.project._id,
        title: `Requirement`,
        description: "",
      });
      setRequirementLoading(false);
    } catch (err) {
      console.log(err);
      setRequirementLoading(false);
    }
  };
  const LoadRequirementDetails = (requirementID) => {
    //console.log(requirementID)
    setEditingItem({
      _id: "",
      title: "",
      description: "",
      createdAt: "",
      status: "",
      updatedAt: "",
      notes: [],
    });
    const selectedRequirement = requirementData.filter(
      (item) => item._id === requirementID
    );
    const data = {
      ...updatingValue,
      requirement_id: selectedRequirement[0]._id,
      title: selectedRequirement[0].title,
      description: selectedRequirement[0].description,
    };
    setUpdatingValue(data);
    setTimeout(() => {
      setEditingItem(selectedRequirement[0]);
    }, 50);
  };
  const setValue = (field, value) => {
    const data = { ...updatingValue };
    data[field] = value;
    setUpdatingValue(data);
  };
  const ModifyRequirementTitle = (e) => {
    try {
      setTitleEditing(true);
      setValue("title", e.target.value);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(async () => {
        const responseData = await projectRequirementUpdation({
          ...updatingValue,
          title: e.target.value,
        });
        if (responseData && responseData.success) {
          setTitleEditing(false);
        }
      }, 2000);
    } catch (error) {
      throw error;
    }
  };
  const setFocus = () => {
    setTimeout(() => {
      titleRef.current.focus();
    }, 100);
  };
  const UpdateRequirement = async () => {
    try {
      seteditLoading(true);
      const responseData = await projectRequirementUpdation(updatingValue);
      if (responseData && responseData.success) {
        const selectedRequirement = responseData.project.requirement.filter(
          (item) => item._id === updatingValue.requirement_id
        );
        // setEditingItem(selectedRequirement[0])
        // setUpdatingValue({ project_id: DetailedProjectData.project._id, requirement_id: selectedRequirement[0]._id, title: selectedRequirement[0].title, description: selectedRequirement[0].description })
        toast.success("Requirement updated successfully");
        seteditLoading(false);
      } else {
        seteditLoading(false);
        toast.error(responseData.message);
      }
    } catch (error) {
      throw error;
    }
  };
  const RemoveRequirement = async (requirementID) => {
    try {
      if (
        window.confirm("Are your sure you want to remove this requirement?")
      ) {
        const responseData = await projectRequirementDelete({
          project_id: DetailedProjectData.project._id,
          requirement_id: requirementID,
        });
        if (responseData && responseData.success) {
          if (responseData.project.requirement.length) {
            const selectedRequirement = responseData.project.requirement[0];
            setEditingItem(selectedRequirement);
            setUpdatingValue({
              project_id: DetailedProjectData.project._id,
              requirement_id: selectedRequirement._id,
              title: selectedRequirement.title,
              description: selectedRequirement.description,
            });
          } else {
            setEditingItem({
              _id: "",
              title: "",
              description: "",
              createdAt: "",
              status: "",
              updatedAt: "",
              notes: [],
            });
            setUpdatingValue({
              project_id: "",
              requirement_id: "",
              title: "",
              description: "",
            });
          }
          toast.success("Requirement removed successfully");
        } else {
          toast.error(responseData.message);
        }
      }
    } catch (error) {
      throw error;
    }
  };
  const setInitailDataDisplay = () => {
    if (requirementData.length) {
      const selectedRequirement =
        editingItem._id &&
        requirementData.filter((item) => item._id === editingItem._id);
      if (selectedRequirement.length) {
        setEditingItem(selectedRequirement[0]);
        setUpdatingValue({
          project_id: DetailedProjectData.project._id,
          requirement_id: selectedRequirement[0]._id,
          title: selectedRequirement[0].title,
          description: selectedRequirement[0].description,
        });
      } else {
        setEditingItem(requirementData[0]);
        setUpdatingValue({
          project_id: DetailedProjectData.project._id,
          requirement_id: requirementData[0]._id,
          title: requirementData[0].title,
          description: requirementData[0].description,
        });
      }
    } else {
      setEditingItem({
        _id: "",
        title: "",
        description: "",
        createdAt: "",
        status: "",
        updatedAt: "",
        notes: [],
      });
      setUpdatingValue({
        project_id: "",
        requirement_id: "",
        title: "",
        description: "",
      });
    }
  };
  const AddNote = async (requirementID) => {
    try {
      setNotAdding(true);

      const responseData = await projectRequirementNoteCreation({
        project_id: DetailedProjectData.project._id,
        requirement_id: requirementID,
        noteTitle: `Note`,
        noteDescription: "",
      });
      if (responseData && responseData.success) {
        setNotAdding(false);
        toast.success("Note created successfully");
      } else {
        setNotAdding(false);
        toast.error(responseData.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const EditNote = async (updatingValues) => {
    try {
      setNoteUpdating(true);
      const responseData = await projectRequirementNoteUpdation(updatingValues);
      if (responseData && responseData.success) {
        setNoteUpdating(false);
        toast.success("Note updated successfully");
      } else {
        setNoteUpdating(false);
        toast.error(responseData.message);
      }
    } catch (error) {
      throw error;
    }
  };
  const RemoveNote = async (removingNoteData) => {
    const responseData = await projectRequirementNoteDelete({
      ...removingNoteData,
    });
    if (responseData && responseData.success) {
      toast.success("Note removed successfully");
    } else {
      toast.error(responseData.message);
    }
  };
  useEffect(() => {
    setInitailDataDisplay();
  }, [DetailedProjectData]);

  return (
    <>
      <div className="flex flex-col lg:flex-row  ">
        <div className="md:basis-1/5">
          <ul className="pl-0">
            <li key="requirement-add-button" className="text-sm pb-6 bg-white">
              <button
                onClick={AddRequirement}
                type="button"
                className=" w-full font-title flex items-center justify-center bg-white text-[#1D1D1D] text-tiny font-medium border rounded-full border-gray-300 transition duration-300 ease-out hover:bg-opacity-80 px-7 h-[40px]"
              >
                {isRequirementLoading ? (
                  <Loader />
                ) : (
                  <>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 9 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M4.50002 1.2915V7.70817M1.29169 4.49984H7.70835"
                        stroke="#545454"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Add
                  </>
                )}
              </button>
            </li>
          </ul>
          <ul className="rounded-xl bg-[#F9F9F9] w-full py-5">
            {requirementData.length ? (
              requirementData.map((requirement) => {
                return (
                  <li
                    key={`requirement_${requirement._id}`}
                    onClick={() => {
                      LoadRequirementDetails(requirement._id);
                    }}
                    className={`text-sm font-thin px-4 py-2 font-title flex justify-start cursor-pointer`}
                  >
                    <button
                      className={`w-full flex justify-between items-center px-4 font-thin  ${
                        editingItem._id === requirement._id
                          ? "bg-white py-2"
                          : ""
                      }`}
                    >
                      {requirement.title}
                      {moment(requirement.createdAt).diff(
                        moment(requirement.updatedAt)
                      ) !== 0 ? (
                        ""
                      ) : (
                        <span>
                          <small className="ml-2 text-green-300 text-xs italic">
                            new
                          </small>
                        </span>
                      )}
                      <svg
                        width="6"
                        height="12"
                        viewBox="0 0 6 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={` ${
                          editingItem._id === requirement._id ? "" : "hidden"
                        }`}
                      >
                        <path
                          d="M0.660256 1.04139C0.58393 1.11952 0.541199 1.22442 0.541199 1.33364C0.541199 1.44287 0.58393 1.54776 0.660256 1.62589L4.92413 6.00002L0.660256 10.3733C0.58393 10.4514 0.541199 10.5563 0.541199 10.6655C0.541199 10.7747 0.58393 10.8796 0.660256 10.9578C0.697353 10.9959 0.741706 11.0261 0.790698 11.0468C0.839691 11.0675 0.892329 11.0782 0.945506 11.0782C0.998683 11.0782 1.05132 11.0675 1.10031 11.0468C1.14931 11.0261 1.19366 10.9959 1.23076 10.9578L5.76501 6.30539C5.84464 6.22369 5.88921 6.11411 5.88921 6.00002C5.88921 5.88592 5.84464 5.77634 5.76501 5.69464L1.23076 1.04227C1.19366 1.00417 1.14931 0.973883 1.10031 0.953206C1.05132 0.932528 0.998683 0.921875 0.945506 0.921875C0.892329 0.921875 0.839691 0.932528 0.790698 0.953206C0.741706 0.973883 0.697353 1.00417 0.660256 1.04227V1.04139Z"
                          fill="#E5EAEF"
                        />
                      </svg>
                    </button>
                  </li>
                );
              })
            ) : (
              <li
                key="no-data-item"
                className="text-sm p-3 border-b border-r border-gray-200 last-of-type:border-0 bg-white hover:bg-primary-light flex items-center justify-center"
              >
                No data
              </li>
            )}
          </ul>
        </div>
        <div className="basis-4/5  md:pl-8 mr-5">
          {editingItem._id ? (
            <>
              <div className="flex flex-col bg-white px-5 rounded-xl">
                <div className="w-full flex items-center lg:flex-row justify-between pb-4">
                  <div className="w-3/4 flex items-center lg:flex-row justify-start">
                    <input
                      value={updatingValue.title}
                      onChange={(e) => {
                        ModifyRequirementTitle(e);
                      }}
                      onBlur={() => {
                        !updatingValue.title &&
                          setValue("title", editingItem.title);
                      }}
                      ref={titleRef}
                      type="text"
                      name="title"
                      placeholder="Title"
                      className="mr-2 items-center focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-sm border rounded-md h-10 py-1 px-4  w-150 placeholder-secondary-grey-300 font-title font-semibold border-secondary-grey-400"
                    />
                    {isTitleEditing ? (
                      <span className="ml-1 mr-2 w-15 text-secondary-grey-300 font-medium text-xs italic font-base ">
                        saving ...
                      </span>
                    ) : (
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                        className="ml-3"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_266_481)">
                          <path
                            d="M11.5782 7.10935C11.172 9.1406 9.6404 11.0532 7.49134 11.4806C6.44321 11.6893 5.35594 11.5621 4.38434 11.1169C3.41275 10.6718 2.60636 9.93144 2.08001 9.00133C1.55365 8.07122 1.33415 6.99876 1.45277 5.93665C1.57139 4.87454 2.02207 3.87692 2.74065 3.08585C4.21453 1.46248 6.70322 1.0156 8.73447 1.8281"
                            stroke="#057D54"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.672 6.29688L6.70325 8.32812L11.5782 3.04688"
                            stroke="#057D54"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_266_481">
                            <rect width="13" height="13" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    <span className="hidden md:block ml-2 w-20  text-secondary-grey-300 font-normal text-xs font-base ">
                      Last updated
                    </span>
                    <span className="hidden md:block w-40 text-secondary-grey-300 font-normal text-xs  font-base ">
                      {moment().format("MMMM DD, YYYY")}
                    </span>

                    <div className="block sm:flex md:hidden">
                      <p className="w-20  text-secondary-grey-300 font-normal text-xs font-base ">
                        Updated on
                      </p>
                      <p className="w-40 text-secondary-grey-300 font-normal text-xs  font-base ">
                        {moment().format("MMMM DD, YYYY")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mb-5 h-60 common-editor   rounded-xl relative ">
                  <ReactQuill
                    className="font-xl h-full bg-white rounded-xl border border-secondary-grey-400"
                    theme="snow"
                    value={updatingValue.description}
                    onChange={(val) => {
                      setValue("description", val);
                    }}
                  />
                </div>

                <div className="flex items-center lg:flex-row justify-start">
                  <button
                    disabled={false}
                    onClick={() => {
                      UpdateRequirement();
                    }}
                    className=" mr-2 font-title flex items-center justify-between border border-[#057D54] bg-[#057D54] text-white text-xs transition duration-300 ease-out hover:bg-opacity-80  px-8 py-2  rounded-md font-medium"
                  >
                    {isEditLoading ? <Loader /> : ""}
                    Update
                  </button>
                  <button
                    className="group  px-7 py-2 font-title text-xs flex items-center justify-between hover:bg-[#057D54] text-black  hover:text-white border border-[#057D54] hover:border-transparent transition duration-300 ease-out rounded-md "
                    title="group remove requirement"
                    onClick={() => RemoveRequirement(editingItem._id)}
                  >
                    <svg
                      className="fill-[#057D54] group-hover:fill-white mr-2"
                      width="17"
                      height="17"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    >
                      <path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-9 4c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm-2-7h-4v1h4v-1z" />
                    </svg>
                    Delete
                  </button>
                </div>
              </div>

              <div className="w-full flex flex-col lg:flex-col md:flex-col pt-6 pb-4">
                <Notes
                  ProjectData={DetailedProjectData}
                  RequirementData={requirementData}
                  editingItem={editingItem}
                  notes={editingItem.notes.reverse()}
                  setEditingItem={setEditingItem}
                  AddNote={AddNote}
                  EditNote={EditNote}
                  RemoveNote={RemoveNote}
                  isNoteAdding={isNoteAdding}
                  isNoteUpdating={isNoteUpdating}
                />
              </div>
            </>
          ) : (
            <div className="bg-secondary-grey-100 text-sm font-medium text-center p-8 mt-10 text-gray-500 text-center">
              {requirementData.length ? (
                <Loader />
              ) : (
                <span className="block mb-1">No data</span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapState = (state) => ({});
const mapDispatch = (dispatch) => ({
  projectRequirementCreation:
    dispatch.projectDetails.projectRequirementCreation,
  projectRequirementUpdation:
    dispatch.projectDetails.projectRequirementUpdation,
  projectRequirementDelete: dispatch.projectDetails.projectRequirementDelete,
  projectRequirementNoteCreation:
    dispatch.projectDetails.projectRequirementNoteCreation,
  projectRequirementNoteUpdation:
    dispatch.projectDetails.projectRequirementNoteUpdation,
  projectRequirementNoteDelete:
    dispatch.projectDetails.projectRequirementNoteDelete,
});

export default connect(mapState, mapDispatch)(Requirement);
