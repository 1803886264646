import { months } from "moment/moment";
import React from "react";
import moment from "moment";

function MilestoneDetailsModal({
  setMilestoneDetails,
  correspondingMilestone,
}) {
  const colours = correspondingMilestone.taskCountByStatus;
  const createdAt = correspondingMilestone.createdAt;
  const createdBy = correspondingMilestone.created_by.first_name;
  const startDate = correspondingMilestone.start_date;
  const start_date = new Date(startDate);
  const date = new Date(createdAt);
  const endDate = correspondingMilestone.end_date;
  const updatedAt = correspondingMilestone.updatedAt;

  const formattedDatee = correspondingMilestone.createdAt;
  const formattedDate = moment(formattedDatee).format("DD MMM YYYY");
  const formattedTime = moment(formattedDatee).format("hh:mm A");
  const formattedEndDate = moment(endDate).format("DD MMM YYYY");
  const lastUpdated = moment(updatedAt).format("DD MMM YYYY");

  const options_start = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedStartDate = date.toLocaleDateString("en-US", options_start);

  const statusOptions = [
    { label: "Todo", value: 2 },
    { label: "In Progress", value: 2 },
    { label: "QA", value: 3 },
    { label: "Production", value: 2 },
    { label: "Others", value: 3 },
  ];
  return (
    <div>
      <div
        className="offcanvas offcanvas-end fixed bottom-0 flex flex-col max-w-full bg-white z-50 bg-clip-padding shadow-lg outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-q pl-2"
        tabIndex="-1"
        id="offcanvasTaskDetails"
        aria-labelledby="offcanvasTaskDetailsLabel"
      >
        <div className="offcanvas-header flex items-center justify-between ">
          <div className="  w-full filter check border-b ">
            <h5
              className="offcanvas-title flex items-center  mb-0 gap-2 p-4 leading-normal font-semibold text-lg text-black"
              id="offcanvasTaskDetailsLabel"
            >
              {correspondingMilestone.title}

              {correspondingMilestone.is_current_milestone && (
                <button className="bg-[#1E7400] w-[60px] rounded text-white text-[11px]">
                  Current
                </button>
              )}
            </h5>
          </div>

          <div className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="cursor-pointer w-6 h-6 mr-5"
              onClick={() => setMilestoneDetails(false)}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <div className="flex justify-between space-x-3 mb-4 p-4">
          <div className="flex flex-col">
            <p className="text-sm  text-[#5F5F5F] font-normal py-5">
              {correspondingMilestone.description}
            </p>
            <label className="text-sm font-semibold mb-4">Overview</label>
            <div className="flex flex-wrap gap-5">
              <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                  No. of Groups
                </label>
                <label className="text-3xl text-[#5F5F5F] font-semibold">
                  {correspondingMilestone.groupCount}
                </label>
              </div>
              <div className="flex flex-col text-center items-center gap-1  p-2 rounded-lg bg-[#F7F8FA]">
                <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                  No. of Tasks
                </label>
                <label className="text-3xl text-[#5F5F5F]  font-semibold">
                  {correspondingMilestone.taskCount}
                </label>
              </div>
              <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                <label className=" text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                  Estimated Time
                </label>
                <label className=" flex items-center text-3xl text-[#5F5F5F] font-semibold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-clock mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                  </svg>
                  {correspondingMilestone.totalEstimate}h
                </label>
              </div>
              <div className="flex flex-col text-center items-center gap-1  p-2 rounded-lg bg-[#F7F8FA]">
                <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                  Logged Time
                </label>
                <label className=" flex items-center text-3xl text-[#5F5F5F] font-semibold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-clock mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                  </svg>
                  {correspondingMilestone.totalTaskTimeLogged}h
                </label>
              </div>
              <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                  Created at
                </label>
                <div className="flex flex-col text-start">
                  <label className="text-xs text-[#5F5F5F] font-semibold inline-block">
                    {formattedDate}
                  </label>
                  <label className="text-xs text-[#5F5F5F] font-semibold inline-block">
                    {formattedTime} {""} by {""} {createdBy}
                  </label>
                </div>
              </div>
              {correspondingMilestone.start_date && (
                <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                  <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                    Start Date
                  </label>
                  <label className="text-xs text-[#5F5F5F] font-semibold">
                    {formattedStartDate}
                  </label>
                </div>
              )}
              {correspondingMilestone.end_date && (
                <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                  <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                    End Date
                  </label>
                  <label className="text-xs text-[#5F5F5F] font-semibold">
                    {formattedEndDate}
                  </label>
                </div>
              )}

              <div className="flex flex-col text-center items-center gap-1 p-2 rounded-lg bg-[#F7F8FA]">
                <label className="text-[13px] whitespace-nowrap text-gray-400 font-semibold">
                  Last Update
                </label>
                <label className="text-xs text-[#5F5F5F] font-semibold">
                  {lastUpdated}
                </label>
              </div>
            </div>
            <label className="text-sm font-semibold mt-4 mb-4">Status</label>
            <div className="flex items-center flex-wrap gap-5">
              <div
                className={`${
                  colours.Todo?.bg_color[0] || "bg-gray-100"
                } flex items-center rounded-lg gap-6 py-1 px-4`}
              >
                <label className="text-[13px]  text-gray-400 font-semibold">
                  Todo
                </label>
                <label className="text-xl text-[#969393] font-semibold">
                  {correspondingMilestone.taskCountByStatus?.Todo?.count || 0}
                </label>
              </div>
              <div
                className={`${
                  colours.InProgress?.bg_color[0] || "bg-yellow-100"
                } flex items-center rounded-lg gap-6 py-1 px-4`}
              >
                <label className="text-[13px] text-gray-400 font-semibold">
                  In Progress
                </label>
                <label className="text-xl text-[#CA8A04] font-semibold">
                  {correspondingMilestone.taskCountByStatus?.InProgress
                    ?.count || 0}
                </label>
              </div>
              <div
                className={`${
                  colours.QA?.bg_color[0] || "bg-green-100"
                } flex items-center rounded-lg gap-6 py-1 px-4`}
              >
                <label className="text-[13px] text-gray-400 font-semibold">
                  QA
                </label>
                <label className="text-xl text-[#3CC13C] font-semibold">
                  {correspondingMilestone.taskCountByStatus?.QA?.count || 0}
                </label>
              </div>
              <div
                className={`${
                  colours.Production?.bg_color[0] || "bg-violet-100"
                } flex items-center rounded-lg gap-6 py-1 px-4`}
              >
                <label className="text-[13px] text-gray-400  font-semibold">
                  Production
                </label>
                <label className="text-xl text-[#3E66FB] font-semibold">
                  {correspondingMilestone.taskCountByStatus?.Production
                    ?.count || 0}
                </label>
              </div>
              <div className=" bg-[#FFEDED] flex items-center rounded-lg gap-6 py-1 px-4">
                <label className="text-[13px] text-gray-400 font-semibold">
                  Others
                </label>
                <label className="text-xl text-[#D57272] font-semibold">
                  9
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MilestoneDetailsModal;
