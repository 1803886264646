import { auth } from "./auth"
import { counter } from "./counter"
import { projects } from "./projects"
import { people } from "./people"
import { projectDetails } from "./projectDetails"
import { newProjectDetails } from "./newProjectDetails"
import { issues } from "./issues"
import { time } from "./time"
import { trashandStatus } from "./trashandStatus"
import {  permissions } from "./permissions"

export default {
    counter,
    projects,
    auth,
    people,
    projectDetails,
    issues,
    time,
    trashandStatus,
    newProjectDetails,
    permissions
}