// export const API_BASE_PATH = "http://localhost:3001/api";
// export const API_BASE_PATH = "https://toobler-document-api.herokuapp.com/api";

//main branch = staging
export const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;
console.log(API_BASE_PATH);
//production branch = production
//export const API_BASE_PATH = "https://availability-api.toobler.com/api";

export const API_ROUTES = {
  "soft-del-milestone": "project/milestone/soft-del-milestone",
  "soft-delete-groups": "project/group/soft-delete-groups",
  "edit-milestone": "project/milestone/edit-milestone",
  "edit-groups": "project/group/edit-groups",
  projects: "/project/allprojects",
  login: "/login",
  token: "/token",
  "project-create": "/project/create",
  "project-client": "/project/allclients",
  "project-name-search": "/project/search/titlesearch/",
  "project-technology": "/project/alltechnologies",
  "forgot-password": "/forgot-password",
  "reset-password": "/reset-password",
  "search-user": "/search-user",
  "invite-user": "/invite-user",
  designation: "/designations",
  roles: "/roles",
  skills: "/skills",
  register: "/register",
  members: "/members",
  "all-members": "/all-members",
  "all-members-only": "/get-all-users",
  "project-detail": "project/",
  "list-tasks": "project/task/list-tasks",
  "get-logtime": "project/timelog/get-logtime",
  "list-task-history": "project/task/list-task-update-history",
  "create-tasks": "project/task/create-tasks",
  "edit-tasks": "project/task/edit-tasks",
  "delete-tasks": "project/task/soft-del-tasks",
  "soft-del-tasks": "project/soft_del_tasks",
  "add-status": "kproject/add_status",
  "add-comment": "project/task/add-comment",
  "list-comments": "project/get_comments",
  "list-milestone": "project/milestone/get-milestone",
  "add-milestone": "project/milestone/create-milestone",
  "list-group": "project/group/get-groups",
  "add-group": "project/group/create-groups",
  "list-timelogs": "project/timelog/get-logtime",
  "all-issues": "/project/issues/all-issues/",
  "all-invite-user": "/all-invite-user",
  "user-availability-calender": "/user-availability-calender",
  "project-status-change": "/project/update/status/",
  "project-description-change": "/project/update/",
  session: "/session/",
  "project-requirement": "/project/requirement/",
  "project-technology-update": "/project/technology/",
  "project-roadmap": "/project/roadmap/",
  "member-availability-calender": "/member-availability-calender",
  "member-availability-statistics": "/member-availability-statistics",
  "member-availability-statistics-user_id": "/user-availability-calender",
  "profile-update": "/profile-update",
  change_password: "/change_password",
  time_entries: "/tw/time/tw-get-time-entries",
  booked_time: "/tw/time/member-booked-hours",
  add_log_time: "/project/timelog/new-logtime",
  get_task_log_time: "/project/timelog/get-logtime",
  "edit-timelog": "/project/timelog/edit-logtime",
  "delete-timelog": "/project/timelog/softdel-logtime",
  "project-files-upload": "/project/files/upload",
  "project-files-get": "/project/files/get",
  "project-files-download": "/project/files/download",
  "project-files-delete": "/project/files/delete",
  "tasks-by-milestone": "/project/task/list-tasks-by-milestone",
  "list-all-trash": "/project/trash/list-all-trash",
  "permanent-delete-trashedtasks": "/project/task/delete-tasks",
  "restore-trashed-task": "/project/trash/restore-task",
  "list-status": "/project/task/list-status",
  "create-status": "/project/task/add-status",
  "delete-status": "/project/task/delete-status",
  "edit-status": "/project/task/edit-status",
  "list-task-and-milestones": "/project/task/list-task-and-milestones",
  "get-first-milestone": "/project/milestone/get-first-milestone",
  "get-one-milestone": "/project/milestone/get-one-milestone",
  "user-bookings": "/user-bookings",
  "all-projects-of-user": "/all-projects-of-user",
  "list-one-user-permission": "/permissions/list-one-user-permission",
  "edit-permission": "/permissions/edit-permission"
};
